
import 'slick-carousel'
export default function slider() {

  let sameSliders = $('#slider');
  if (sameSliders) {
    sameSliders.slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      touchThreshold: 50,
      prevArrow: '<div class="slick-arrow-p"></div>',
      nextArrow: '<div class="slick-arrow-n"></div>',
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
      ]
    });
  }
}