import all from "./modules/utilities";
import slider from "./modules/slider";
import onScroll from "./modules/onscroll";


document.addEventListener('DOMContentLoaded', e => {
    slider();
    onScroll();

    const anchor = $('a[href^="#"]');
    anchor.on('click', function () {
        $('html, body').animate({
            scrollTop: $($(this).attr('href')).offset().top
        }, 500);
        return false;
    });


    // open/close main
    let toggleBtn = document.querySelector('.toggleMenu');
    let navA = document.querySelectorAll('.nav a');

    toggleBtn.addEventListener('click', evt => {
        toggleBtn.parentNode.classList.toggle('active')
    });

    navA.forEach(a => {
        a.addEventListener('click', evt => toggleBtn.parentNode.classList.toggle('active'))
    });


    //pagination
    const pagination = () => {
        let count = 0;
        const dataIndex = document.querySelectorAll('[data-index]'),
            currentSectionPos = (window.innerHeight/2) ,
            dots = document.querySelectorAll('.nav a');

        const pagination = () => {
            const lastOne = Array.from(dataIndex).reverse().findIndex((d) => d.getBoundingClientRect().top < currentSectionPos);
            if (count !== lastOne){
                dots.forEach(d => d.classList.remove('active'));
                dots[dataIndex.length  - lastOne -1 ].classList.add('active');
            }
            count = lastOne;
        };

        pagination();
        window.addEventListener('scroll', pagination);
    };
    pagination()

});